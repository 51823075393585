import React from "react"
import { graphql } from "gatsby"

import Layout, { Section } from "../components/Layout"
import { TwitterTimelineEmbed } from "react-twitter-embed"
import PageImage from "../components/PageUnsplashImage"

import { SocialLinks } from "../components/Social"

const Index = ({ data }) => {
  const post = data.markdownRemark

  const title = post.frontmatter.title
  const image = post.frontmatter.image
  const imageCredit = post.frontmatter.imageCredit

  return (
    <Layout className="news">
      <PageImage src={image} credit={imageCredit}>
        <h1 className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen">
          {title}
        </h1>
      </PageImage>

      <div className="social-links">
        <SocialLinks />
      </div>
      <Section className="twitter-feed">
        <TwitterTimelineEmbed
          sourceType="profile"
          screenName="IESaude"
          options={{ width: "75%" }}
        />
      </Section>
    </Layout>
  )
}

export default Index

export const query = graphql`
  query NewsQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        imageCredit {
          author
          href
        }
        image
      }
    }
  }
`
